<template>
<page title="专家库详情">
  <commonbanner></commonbanner>
  <article id="page-person">
    <section class="container container-main mt-4 mb-4">
      <Collection  :name="`expert/${id}`"   v-slot="{ model }">
        <div class="orgdetails">
          <div class="orgdetails-header">
            <h4 class="orgdetails-title">专家基本信息</h4>
            <div class="tel">联系电话：<span>{{showPhone(model.contact_number)}}</span></div>
          </div>
          <div class="orgdetails-body clearfix">
            <div class="lawwer-staff-wrap">
              <div class="pic">
                <div rowspan="6" class="org_pic">
                  <img :src="getExpertPhotoURL(model)" />
                </div>
              </div>
              <div class="content-box">
                <h3>{{model.expert_name}}</h3>
                <div class="pull-left sm"> <span class="tit">职业：</span> <span class="cont tex-name">职业： <i>{{model.position}}</i> </span> </div>
                <div class="pull-left"> <span class="tit">业务专长：</span> <span class="cont"> {{model.specialty}} </span> </div>
                <div class="pull-left sm"> <span class="tit">执业证号：</span> <span class="cont tex-name">{{ model.practice_code }}</span> </div>
                <div class="pull-left"> <span class="tit">联系地址：</span> <span class="cont">{{model.correspondence_address}}</span> </div>
              </div>
              <div class="cont-number-box">
                <div class="staff-auth pull-right">
                  <ul class="auth-list clearfix">
                    <li>
                      <div class="auth-text"> 身体状况 </div>
                      <div class="circle"> {{ model.physical_condition }} </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="staff-info">
            <div class="tab">
              <ul class="tab-head clearfix">
                <li><a href="#info01" class="">执业信息</a></li>
                <li><a href="#info02" class="">工作情况</a></li>
                <li><a href="#info03" class="">个人成就与业务分类</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-section" id="info01">
                  <h4 class="head">执业信息</h4>
                  <div class="body">
                    <ul class="info-list clearfix">
                      <li>
                        <label class="name">性别：</label>
                        {{model.expert_gender=="2"?"女":"男"}}</li>
                      <li>
                        <label class="name">年龄：</label>
                        37</li>
                      <li>
                        <label class="name">民族：</label>
                        {{ model.expert_nation }}</li>
                      <li>
                        <label class="name">政治面貌：</label>
                        {{model.politics_status}} </li>
                      <li>
                        <label class="name">职业：</label>
                        {{model.position}}
                      </li>
                      <li>
                        <label class="name">职称：</label>
                        {{model.job_title}}</li>
                      <li>
                        <label class="name">个人简介：</label>
                        {{model.self_opinion}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="staff-info">
            <div class="tab">
              <div class="tab-content">
                <div class="tab-section" id="info02">
                  <h4 class="head">工作情况</h4>
                  <div class="body">
                    <ul class="info-list clearfix">
                      <li>
                        <label class="name">专业：</label>
                        {{ model.profession }}</li>
                        <li>
                        <label class="name">最高学历</label>
                        {{ model.highest_degree }}</li>
                        <li>
                        <label class="name">最高学位</label>
                        {{ model.highest_diploma }}</li>
                      <li>
                        <label class="name">资格证书：</label>
                        {{ model.qualification_certificate }}</li>
                      <li>
                        <label class="name">资格证书编号：</label>
                        {{ model.qualification_certificate_no }} </li>
                      <li>
                        <label class="name">执业证书编号：</label>
                        {{ model.practicing_certificate_no }}
                      </li>
                    </ul>
                    <p>
                    <label class="name">工作经历：</label>
                    {{model.work_experience}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="staff-info">
            <div class="tab">
              <div class="tab-content">
                <div class="tab-section" id="info03">
                  <h4 class="head">个人成就与业务分类</h4>
                  <div class="body">
                    <ul class="info-list clearfix">
                      <!-- <li>
                        <label class="name">业务领域</label>
                        {{ model.business_area }}</li> -->
                        <li>
                        <label class="name">研究专长：</label>
                        {{ model.specialty }}</li>
                        <li>
                        <label class="name">业务领域</label>
                        公共法律服务人才库领域分类</li>
                      <li>
                        <label class="name">专长领域：</label>
                        律师服务类</li>
                    </ul>
                    <p>
                      <label class="name">工作经历：</label>
                      {{ model.tremendous_achievements }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collection>
    </section>
  </article>
</page>
</template>
<script>
import Collection from '@/components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
export default {
  name: 'Expert',
  components: {
    Commonbanner,
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      modal: {}
    }
  },
  activated () {
    this.id = this.$route.params.id
  }
}
</script>
<style scoped src="../../assets/css/expertshow.css"></style>
